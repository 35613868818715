// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/device.GET"
export interface IDeviceInfo {
    serial_number: string | undefined;
    firmware_version: string | undefined;
    kernel_version: string | undefined;
    build_number: string | undefined;
    model: string | undefined;
    mac_address: string | undefined;
    name: string | undefined;
    location: string | undefined;
    speaker_type: 'none' | 'single' | 'dual' | undefined;
    mic_type: 'none' | 'hands_free' | undefined;
    display_type: 'none' | 'single' | 'dual' | undefined;
    strobe_type: 'none' | 'RGB' | undefined;
    uptime: string | undefined;
    storage_capacity: string | undefined;
    storage_used: string | undefined;
}

export enum DeviceInfoSpeakerType {
    none = 'none',
    single = 'single',
    dual = 'dual',
}

export enum DeviceInfoMicType {
    none = 'none',
    handsFree = 'hands_free',
}

export enum DeviceInfoDisplayType {
    none = 'none',
    single = 'single',
    dual = 'dual',
}

export enum DeviceInfoStrobeType {
    none = 'none',
    rgb = 'RGB',
}

export class DeviceInfoModel implements IDeviceInfo {
    serial_number: string | undefined;
    firmware_version: string | undefined;
    kernel_version: string | undefined;
    build_number: string | undefined;
    model: string | undefined;
    mac_address: string | undefined = '';
    name: string | undefined;
    location: string | undefined;
    speaker_type: 'none' | 'single' | 'dual' | undefined;
    mic_type: 'none' | 'hands_free' | undefined;
    display_type: 'none' | 'single' | 'dual' | undefined;
    strobe_type: 'none' | 'RGB' | undefined;
    uptime: string | undefined;
    storage_capacity: string | undefined;
    storage_used: string | undefined;
    get formatted_mac_address(): string {
        if (this.mac_address) {
            const matches = this.mac_address.match(/(..)/g);
            if (matches) {
                return matches.join(':');
            }
        }

        return '';
    }

    constructor(obj?: IDeviceInfo) {
        this.serial_number = obj?.serial_number;
        this.firmware_version = obj?.firmware_version;
        this.kernel_version = obj?.kernel_version;
        this.build_number = obj?.build_number;
        this.model = obj?.model;
        this.mac_address = obj?.mac_address;
        this.name = obj?.name;
        this.location = obj?.location;
        this.speaker_type = obj?.speaker_type;
        this.mic_type = obj?.mic_type;
        this.display_type = obj?.display_type;
        this.strobe_type = obj?.strobe_type;
        this.uptime = obj?.uptime;
        this.storage_capacity = obj?.storage_capacity;
        this.storage_used = obj?.storage_used;
    }

    public hasStrobe() {
        return this.strobe_type !== DeviceInfoStrobeType.none;
    }

    public hasMic(): boolean {
        return this.mic_type !== DeviceInfoMicType.none;
    }

    public hasDisplay(): boolean {
        return this.display_type !== DeviceInfoDisplayType.none;
    }

    public hasSpeaker(): boolean {
        return this.speaker_type !== DeviceInfoSpeakerType.none;
    }
}

<div class="range-input-container">
    <div class="label-toggle-container">
        <mat-slide-toggle color="primary" [(ngModel)]="is_enabled" (change)="onToggleChange($event)"></mat-slide-toggle>
        <span>{{ label }}</span>
    </div>

    <mat-slider
        id="slider"
        [min]="min"
        [max]="max"
        [showTickMarks]="showTicks"
        [discrete]="true"
        [disabled]="!is_enabled"
        [displayWith]="displayWith"
    >
        <input
            matSliderStartThumb
            [(ngModel)]="startValue"
            (ngModelChange)="onStartChange($event)"
            [disabled]="!is_enabled"
        />
        <input matSliderEndThumb [(ngModel)]="endValue" (ngModelChange)="onEndChange($event)" [disabled]="isDisabled" />
    </mat-slider>
</div>

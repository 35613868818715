export interface IAudioAsset {
    name: string | undefined;
    duration: number | undefined;
    date_created: Date | undefined;
    size: number | undefined;
    used_in: string | undefined;
    file: string | undefined;
    playing?: boolean | undefined;
}

export class AudioAssetModel implements IAudioAsset {
    name: string | undefined;
    duration: number | undefined;
    date_created: Date | undefined;
    size: number | undefined;
    used_in: string | undefined;
    file: string | undefined;
    playing?: boolean | undefined;

    constructor(obj?: IAudioAsset) {
        this.name = obj?.name;
        this.duration = obj?.duration;
        this.date_created = obj?.date_created;
        this.size = obj?.size;
        this.used_in = obj?.used_in;
        this.file = obj?.file;
        this.playing = obj?.playing;
    }
}

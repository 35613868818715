import { Component, forwardRef, Input, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-range-input',
    templateUrl: './range-input.component.html',
    styleUrls: ['./range-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RangeInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RangeInputComponent),
            multi: true,
        },
    ],
})
export class RangeInputComponent implements ControlValueAccessor, Validator {
    @Input() min = 0;
    @Input() max = 24;
    @Input() showTicks = true;
    @Input() label = 'label';
    @Input() startValue = 4;
    @Input() endValue = 8;
    @Input() isDisabled = false;
    @Input() is_enabled = true;
    @Input() displayWith: (value: number) => string = (value) => `${value}`;

    @ViewChild('startInput') startInput!: ElementRef<HTMLInputElement>;
    @ViewChild('endInput') endInput!: ElementRef<HTMLInputElement>;

    protected changed: (_value: any) => void = (_value: any) => {};

    protected touched: () => void = () => {};

    protected onValidationChange: () => void = () => {};

    public validInput = true;

    constructor() {}

    public writeValue(value: any): void {
        if (value) {
            this.startValue = value.start;
            this.endValue = value.end;
            this.is_enabled = value.is_enabled;
        }
    }

    public registerOnChange(fn: any): void {
        this.changed = fn;
    }

    public registerOnTouched(fn: any): void {
        this.touched = fn;
    }

    public onStartChange(event: number) {
        const value: number = event;
        this.startValue = value;
        this.onValidationChange();
        this.changed({ start: this.startValue, end: this.endValue, is_enabled: this.is_enabled });
    }

    public onEndChange(event: number) {
        const value: number = event;
        this.endValue = value;
        this.onValidationChange();
        this.changed({ start: this.startValue, end: this.endValue, is_enabled: this.is_enabled });
    }

    public onToggleChange(event: MatSlideToggleChange) {
        this.is_enabled = event.checked;
        this.isDisabled = !event.checked;
        this.changed({ start: this.startValue, end: this.endValue, is_enabled: this.is_enabled });
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public validate(control: AbstractControl<any, any>): ValidationErrors | null {
        if (!control.value.is_enabled) {
            this.validInput = true;
            return null;
        }

        this.validInput =
            control.value.start <= this.max &&
            control.value.start >= this.min &&
            control.value.end <= this.max &&
            control.value.end >= this.min;
        return this.validInput ? null : { invalid: true };
    }

    public registerOnValidatorChange?(fn: () => void): void {
        this.onValidationChange = fn;
    }
}

export interface IAlphaKingSchema {
    $schema: string;
    $id: string;
    description: string;
    type: string;
    properties?: any;
}

export class AlphakingSchemaModel implements IAlphaKingSchema {
    public $schema: string = '';
    public $id: string = '';
    public description: string = '';
    public type: string = '';
    public properties?: any = '';

    constructor(obj?: IAlphaKingSchema) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

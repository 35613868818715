// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/sip.GET"
export interface ISipConfig {
    enable: boolean | undefined;
    registration_interval: number | undefined;
    remote_port: number | undefined;
    outbound_proxy_address: string | undefined;
    outbound_proxy_port: number | undefined;
    registration_mode: 'all' | 'fallback' | string | undefined;
    ip_protocol: string | undefined;
    registrars: Array<ISipRegistrarConfig> | undefined;
}

export interface ISipRegistrarConfig {
    url: string | undefined;
    digest_realm: string | undefined;
    user_id: string | undefined;
    auth_id: string | undefined;
    auth_password: string | undefined;
}

export class SipConfigModel implements ISipConfig {
    enable: boolean | undefined;
    registration_interval: number | undefined;
    remote_port: number | undefined;
    outbound_proxy_address: string | undefined;
    outbound_proxy_port: number | undefined;
    registration_mode: 'all' | 'fallback' | string | undefined;
    ip_protocol: string | undefined;
    registrars: Array<ISipRegistrarConfig> | undefined;

    constructor(obj?: ISipConfig) {
        this.enable = obj?.enable;
        this.registration_interval = obj?.registration_interval;
        this.remote_port = obj?.remote_port;
        this.outbound_proxy_address = obj?.outbound_proxy_address;
        this.outbound_proxy_port = obj?.outbound_proxy_port;
        this.registration_mode = obj?.registration_mode;
        this.ip_protocol = obj?.ip_protocol;
        this.registrars = obj?.registrars?.map((i: ISipRegistrarConfig) => i);
    }
}

// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/revolution.GET"
export interface IRevolutionConfig {
    enable: boolean | undefined;
    notifier_address: string | undefined;
}

export class RevolutionConfigModel implements IRevolutionConfig {
    enable: boolean | undefined;
    notifier_address: string | undefined;

    constructor(obj?: IRevolutionConfig) {
        this.enable = obj?.enable;
        this.notifier_address = obj?.notifier_address;
    }
}

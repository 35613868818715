import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { INavigation } from 'src/app/modules/shared/interfaces/navigation.interface';

@Component({
    selector: 'app-sub-navigation',
    templateUrl: './sub-navigation.component.html',
    styleUrls: ['./sub-navigation.component.scss'],
})
export class SubNavigationComponent {
    @Input() links: INavigation[] | undefined = [];

    constructor(protected _translationService: TranslateService) {}
}

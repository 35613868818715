import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/modules/shared/shared.module';

import { SubNavigationComponent } from './sub-navigation/sub-navigation.component';
import { CardSectionComponent } from './card-section/card-section.component';
import { SliderInputComponent } from './slider-input/slider-input.component';

import { WaveSurferComponent } from './wave-surfer/wave-surfer.component';

import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarLocateComponent } from './sidebar/sidebar-locate/sidebar-locate.component';
import { RangeInputComponent } from './range-input/range-input.component';
import { ServiceStatusComponent } from './sidebar/service-status/service-status.component';
import { SidebarStatusItemComponent } from './sidebar/sidebar-status-item/sidebar-status-item.component';
import { DeviceInfoComponent } from './sidebar/device-info/device-info.component';
import { StorageComponent } from './sidebar/storage/storage.component';
import { NextButtonComponent } from './next-button/next-button.component';

const components = [
    SubNavigationComponent,
    CardSectionComponent,
    SliderInputComponent,
    WaveSurferComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarLocateComponent,
    ServiceStatusComponent,
    SidebarStatusItemComponent,
    StorageComponent,
    DeviceInfoComponent,
    SidenavComponent,
    BackButtonComponent,
    RangeInputComponent,
    NextButtonComponent,
];
@NgModule({
    declarations: components,
    imports: [CommonModule, SharedModule],
    exports: [...components],
})
export class CommonComponentsModule {}

// SCHEMA_VALIDATE: "$id": "response.roles.v1./api/settings/role_permissions.GET"
export interface IPermission {
    id: number | undefined;
    role_id: number | undefined;
    resource: string | undefined;
    description: string | undefined;
    create: boolean | undefined | null;
    read: boolean | undefined | null;
    update: boolean | undefined | null;
    delete: boolean | undefined | null;
    access: boolean | undefined | null;
}

export interface ISystemPermission {
    id: number | undefined;
    resource: string | undefined;
    description: string | undefined;
    operations: string[] | undefined;
}

export class PermissionModel implements IPermission {
    public id: number | undefined;
    public role_id: number | undefined;
    public resource: string | undefined;
    public description: string | undefined;
    public create: boolean | undefined | null;
    public read: boolean | undefined | null;
    public update: boolean | undefined | null;
    public delete: boolean | undefined | null;
    public access: boolean | undefined | null;

    constructor(obj?: IPermission) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class SystemPermissionsModel implements ISystemPermission {
    id: number | undefined;
    resource: string | undefined;
    description: string | undefined;
    operations: string[] | undefined;
    constructor(obj?: ISystemPermission) {
        if (obj) {
            this.id = obj.id;
            this.resource = obj.resource;
            this.description = obj.description;
            this.operations = obj.operations;
        }
    }
}

import { Component, Input, EventEmitter, Output } from '@angular/core';

import { NavigationService } from 'src/app/modules/shared/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
    @Input() state: string = 'close';
    @Output() closeEvent: EventEmitter<null> = new EventEmitter();

    constructor(protected _navService: NavigationService, protected _translationService: TranslateService) {}

    public open(): void {
        this.state = 'open';
    }

    public close(): void {
        this.state = 'close';
        this.closeEvent.emit();
    }
}

export interface IResultsConfig {
    testDateTime: Array<TestDateTime>;
    tasks: Array<IncludedTasksValue>;
    includedTasksValues: Array<IncludedTasksValue>;
    includedTestsValues: Array<IncludedTasksValue>;
}

export interface IncludedTasksValue {
    name: string;
    completed: boolean;
}

export interface TestDateTime {
    testDateTime: string;
    speakers: string;
    lightBar: string;
}

export class ResultsModel implements IResultsConfig {
    testDateTime: Array<TestDateTime>;
    tasks: Array<IncludedTasksValue>;
    includedTasksValues: Array<IncludedTasksValue>;
    includedTestsValues: Array<IncludedTasksValue>;

    constructor(obj?: IResultsConfig) {
        this.testDateTime = obj?.testDateTime ?? [];
        this.tasks = obj?.tasks ?? [];
        this.includedTasksValues = obj?.includedTasksValues ?? [];
        this.includedTestsValues = obj?.includedTestsValues ?? [];
    }
}

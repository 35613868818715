// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/informacast.GET"
export interface IInformacastConfig {
    enable: boolean | undefined;
    registration_interval: number | undefined;
    override_sip_volume: boolean | undefined;
    static_server_url: Array<string> | undefined;
    send_button_report: boolean | undefined;
    command_server_port: number | undefined;
}

export class InformacastConfigModel implements IInformacastConfig {
    enable: boolean | undefined;
    registration_interval: number | undefined;
    override_sip_volume: boolean | undefined;
    static_server_url: Array<string> | undefined;
    send_button_report: boolean | undefined;
    command_server_port: number | undefined;

    constructor(obj?: IInformacastConfig) {
        this.enable = obj?.enable;
        this.registration_interval = obj?.registration_interval;
        this.override_sip_volume = obj?.override_sip_volume;
        this.send_button_report = obj?.send_button_report;
        this.command_server_port = obj?.command_server_port;
        this.static_server_url = obj?.static_server_url?.map((i) => i);
    }
}

import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

export interface INavigation {
    title: string;
    routerLink: string;
    icon?: string;
    img?: {
        src: string;
    };
    tooltip?: {
        title: string;
        tooltipPosition?: string;
        showDelay?: number;
        hideDelay?: number;
    };
    children?: Array<INavigation>;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private readonly _nav: Array<INavigation> = [
        {
            title: 'header.dashboard',
            routerLink: '/dashboard',
        },
        {
            title: 'header.priorities',
            routerLink: '/priority',
        },
        {
            title: 'header.services',
            routerLink: '/services',
        },
        {
            title: 'header.hardware',
            routerLink: '/hardware',
            children: [],
        },
        {
            title: 'header.media',
            routerLink: '/media',
        },
        {
            title: 'header.settings',
            routerLink: '/settings',
        },
    ];

    private readonly _servicesSubNav: Array<INavigation> = [
        {
            title: 'routes.services.informacast',
            routerLink: 'informacast',
            icon: ' cast',
        },
        {
            title: 'routes.services.gck',
            routerLink: 'gck',
            icon: 'switch_access_shortcut',
        },
        {
            title: 'routes.services.revolution',
            routerLink: 'revolution',
            icon: 'equalizer',
        },
        {
            title: 'routes.services.sip',
            routerLink: 'sip',
            icon: 'dataset',
        },
    ];

    private readonly _hardwareSubNav: Array<INavigation> = [
        {
            icon: 'volume_up',
            title: 'routes.hardware.speakerMicrophone',
            routerLink: 'speaker-microphone',
        },
        {
            icon: ' hd',
            title: 'routes.hardware.display',
            routerLink: 'display',
        },
        {
            icon: 'fluorescent',
            title: 'routes.hardware.lightbar',
            routerLink: 'light-bar',
        },
        {
            icon: 'videocam',
            title: 'routes.hardware.camera',
            routerLink: 'camera',
        },
        {
            icon: ' sensors',
            title: 'routes.hardware.enviromentalSensors',
            routerLink: 'enviromental-sensors',
        },
        {
            icon: ' smartphone',
            title: 'routes.hardware.accessories',
            routerLink: 'accessories',
        },
        {
            icon: ' assignment_turned_in',
            title: 'routes.hardware.selfTestCenter',
            routerLink: 'self-test-center',
        },
    ];

    private readonly _mediaSubNav: Array<INavigation> = [
        {
            title: 'routes.media.scenes.title',
            routerLink: 'ipx-scenes',
            icon: 'movie_filter',
        },
        {
            title: 'routes.media.screenTakeover',
            routerLink: 'screen-takeover',
            icon: 'cast_connected',
        },
        {
            title: 'routes.media.audioLibrary.title',
            routerLink: 'audio-message',
            icon: 'music_note',
        },
    ];

    private readonly _settingsSubNav: Array<INavigation> = [
        {
            routerLink: 'network',
            title: 'routes.settings.network',
            icon: 'lan',
        },
        {
            routerLink: 'clock',
            title: 'routes.settings.clock',
            icon: 'schedule',
        },
        {
            routerLink: 'rsys',
            title: 'routes.settings.remoteSysLog',
            icon: 'receipt_long',
        },
        {
            routerLink: 'firmware',
            title: 'routes.settings.firmware',
            icon: 'memory',
        },
        {
            routerLink: 'users',
            title: 'routes.settings.userAccounts',
            icon: 'people',
        },
        {
            routerLink: 'device-info',
            title: 'routes.settings.deviceNameLocation',
            icon: 'room',
        },
    ];

    constructor(private _ts: TranslateService) {}

    getNavigation(): Array<INavigation> {
        return this._nav;
    }

    getServicesSubNavigation(): Array<INavigation> {
        return this._servicesSubNav;
    }

    getHardwareSubNavigation(): Array<INavigation> {
        return this._hardwareSubNav;
    }

    getMediaSubNavigation(): Array<INavigation> {
        return this._mediaSubNav;
    }

    getSettingsubNavigation(): Array<INavigation> {
        return this._settingsSubNav;
    }
}

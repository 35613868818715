import { FormArray, FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { DisplayFormModel, IDaySchedule } from 'src/app/modules/core/api/config/display-info.model';
import { CheckboxData } from 'src/app/modules/shared/models/results-checkbox.model';
import { DisplayForm } from '../../models/display-form.model';

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function addCheckboxData(event: MatCheckboxChange, value: CheckboxData, formArray: FormArray): void {
    value.completed = event.checked;
    if (value.completed) {
        formArray.value.push(value);
    } else {
        const value = formArray.value.find((value: CheckboxData) => value === value);
        const index = formArray.value.indexOf(value);
        formArray.value.splice(index, 1);
    }
}

export function addChip(
    event: MatChipInputEvent,
    formControl: FormControl,
    itemList: string[],
    inputValidatorControl?: FormControl
): void {
    const value = (event.value || '').trim();
    if (inputValidatorControl && inputValidatorControl.valid && value) {
        itemList = [value, ...itemList];
        formControl.setValue(itemList);
        event.chipInput!.clear();
    } else if (value && !inputValidatorControl) {
        itemList?.push(value);
        formControl.setValue(itemList);
        event.chipInput!.clear();
    }
}

export function removeChip(item: string, formControl: FormControl, itemList: string[]): void {
    const index = itemList?.indexOf(item);
    itemList?.splice(index, 1);
    formControl.setValue(itemList);
}

export function formatAudioTime(time?: number): string | void {
    if (time) {
        const minutes = Math.floor(time / 60)
            .toString()
            .padStart(2, '0');
        const seconds = Math.floor(time % 60)
            .toString()
            .padStart(2, '0');
        return `${minutes}:${seconds}`;
    }
    return '00:00';
}

export function formatDisplayTimeLabel(value: number): string {
    switch (value) {
        case 0:
            return '12am';
        case 12:
            return '12pm';
        case 24:
            return '12am';
        default:
            return value > 12 ? `${value - 12}pm` : `${value}am`;
    }
}

export function parseScheduleData(schedule: Array<IDaySchedule>): Array<any> {
    return schedule.map((item, index) => ({
        dayOfWeek: daysOfWeek[index % 7],
        schedule: {
            start: item.normal_hour,
            end: item.dimmed_hour,
            is_enabled: item.enable_day,
        },
    }));
}

export function transformFormDisplayScheduleHardwareDataToOriginalShape({
    dimming_schedule,
    ...formData
}: DisplayForm): DisplayFormModel {
    return {
        ...formData,
        schedule: daysOfWeek
            .map((day) => dimming_schedule[day.toLowerCase()])
            .filter((daySchedule) => !!daySchedule)
            .map((daySchedule) => ({
                dimmed_hour: daySchedule.end,
                normal_hour: daySchedule.start,
                enable_day: daySchedule.is_enabled,
            })),
    };
}

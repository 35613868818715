// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/gck.GET"
export interface IGckConfig {
    enable: boolean | undefined;
    device_id: number | undefined;
    multicast_control_group: string | undefined;
    server_has_initialized: boolean | undefined;
}

export class GckConfigModel implements IGckConfig {
    enable: boolean | undefined;
    device_id: number | undefined;
    multicast_control_group: string | undefined;
    server_has_initialized: boolean | undefined;

    constructor(obj?: IGckConfig) {
        this.enable = obj?.enable;
        this.device_id = obj?.device_id;
        this.multicast_control_group = obj?.multicast_control_group;
        this.server_has_initialized = obj?.server_has_initialized;
    }
}

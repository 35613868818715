import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, throwError, Subscription } from 'rxjs';

import { IRequest, IResponse } from './common.interface';
import { UserModel } from './user/user.model';
import { InfoModel } from './models/info.model';

export class AlphaKingApiCall {
    private _user: UserModel | undefined = undefined;
    private _userSub: Subscription | undefined = undefined;

    constructor(private _apiServer: string, private _http: HttpClient) {}

    setUserObservable(ob$: Observable<UserModel | undefined>) {
        this._userSub = ob$.subscribe((user: UserModel | undefined) => (this._user = user));
    }

    public request<T = any>(req: IRequest): Observable<IResponse<T>> {
        const httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('authToken'),
        });

        return this._http.post<IResponse<T>>(this._apiServer + '/internal', req, { headers: httpHeaders }).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err);
            })
        );
    }

    public info(): Observable<InfoModel> {
        return this._http.get<InfoModel>(this._apiServer + '/info').pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err);
            })
        );
    }
}

import { Component, Input } from '@angular/core';
import { DeviceInfoModel, NetworkConfigModel } from 'src/app/modules/core/api/config';

@Component({
    selector: 'app-device-info',
    templateUrl: './device-info.component.html',
    styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent {
    @Input() deviceInfo: DeviceInfoModel | null = null;
    @Input() networkConfig: NetworkConfigModel | null = null;
}

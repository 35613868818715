// SCHEMA_VALIDATE: "$id": "response.roles.v1./api/settings/user.GET"
import { RoleModel } from '../role';
import { IRole } from '../role';

export interface IUser {
    id?: number | undefined;
    username: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    email: string | undefined;
    password: string | undefined | null;
    role?: RoleModel | IRole | undefined;
}

export interface IUsers {
    users: Array<IUser>;
}

export interface IAuth {
    authToken: string;
}

export class UserModel implements IUser {
    id?: number | undefined;
    username: string | undefined;
    first_name: string | undefined;
    last_name: string | undefined;
    email: string | undefined;
    password: string | undefined | null;
    role?: RoleModel | undefined;

    constructor(obj?: IUser) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap, throwError, of, map } from 'rxjs';

import { AlphaKingApiCall } from '../api';

import { UserModel, IUser, IAuth } from './user.model';
import { IResponse } from '../common.interface';

export class UserServices {
    private _currentUser: UserModel | undefined = undefined;
    public set currentUser(user: UserModel | undefined) {
        this._currentUser = user;
        this._currentUser$.next(this._currentUser);
    }
    public get currentUser(): UserModel | undefined {
        return this._currentUser;
    }

    private _currentUser$ = new BehaviorSubject<UserModel | undefined>(undefined);

    public get currentUser$(): Observable<UserModel | undefined> {
        return this._currentUser$.asObservable();
    }

    constructor(private _apiService: AlphaKingApiCall, private _apiServer: string, private _http: HttpClient) {}

    public authenticate(user: string, password: string): Observable<IAuth> {
        const httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: 'Basic ' + btoa(user + ':' + password),
        });

        return this._http.get<IAuth>(this._apiServer + '/auth', { headers: httpHeaders }).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err);
            })
        );
    }

    public logOut(authToken: string): Observable<IResponse> {
        const httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
        });

        return this._http.post<IResponse>(this._apiServer + '/logout', {}, { headers: httpHeaders }).pipe(
            catchError((err: HttpErrorResponse) => {
                return throwError(() => err);
            })
        );
    }

    public getUsers(): Observable<IResponse> {
        return this._apiService
            .request<IResponse<Array<IUser>>>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/users',
            })
            .pipe(
                map((resp: IResponse) => {
                    return {
                        status: resp.status,
                        message: resp.message,
                        data: resp.data,
                    };
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(() => err);
                })
            );
    }

    public getUser(id: number): Observable<UserModel> {
        return this._apiService
            .request<IUser>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/user',
                data: { id },
            })
            .pipe(
                map((resp: IResponse<IUser>) => {
                    return new UserModel(resp.data);
                })
            );
    }

    public getMe(): Observable<UserModel | undefined> {
        return this.getUser(<number>this._currentUser?.id);
    }

    public createUser(user: IUser): Observable<UserModel> {
        return this._apiService
            .request<UserModel>({
                topic: 'roles',
                version: 'v1',
                method: 'PUT',
                resource: '/api/settings/user',
                data: {
                    username: user.username,
                    password: user.password,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    role_id: user.role?.id,
                },
            })
            .pipe(
                map((resp: IResponse<IUser>) => {
                    return new UserModel(resp.data);
                })
            );
    }

    public updateUser(updatedUser: Partial<UserModel>): Observable<UserModel> {
        return this._apiService
            .request({
                topic: 'roles',
                version: 'v1',
                method: 'PATCH',
                resource: '/api/settings/user',
                data: {
                    id: updatedUser.id,
                    username: updatedUser.username,
                    first_name: updatedUser.first_name,
                    last_name: updatedUser.last_name,
                    email: updatedUser.email,
                    password: updatedUser.password,
                    role_id: updatedUser.role?.id,
                },
            })
            .pipe(
                map((res: IResponse) => {
                    return new UserModel(updatedUser as IUser);
                })
            );
    }

    public deleteUser(user_id: number): Observable<IResponse> {
        return this._apiService.request<IResponse>({
            topic: 'roles',
            version: 'v1',
            method: 'DELETE',
            resource: '/api/settings/user',
            data: {
                user_id,
            },
        });
    }
}

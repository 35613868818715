<div id="login-page" *ngIf="infoConfig$ | async as info">
    <ng-particles
        id="tsparticles"
        [options]="options"
        [particlesInit]="particlesInit"
        (particlesLoaded)="particlesLoaded($event)"
    ></ng-particles>

    <mat-card appearance="outlined" [@enter]>
        <article class="left">
            <h2>{{ info?.location }}</h2>
            <h2>IP: {{ info?.ip_address }}</h2>
            <h2>MAC: {{ info?.mac | uppercase }}</h2>

            <img src="/assets/images/product/{{ info?.model }}.png" alt="{{ info?.model }}" />

            <h4>{{ info?.model }}</h4>
            <div>PoE Indoor Wall Mount IP Speaker with LED Display, LED Flasher, Camera and Talkback Microphone</div>
        </article>

        <article class="right">
            <mat-card-content>
                <img class="logo-img" src="assets/images/atlasied-logo-sm-blue.png" alt="Atlas|IED" />

                <h1>Login</h1>

                <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit(loginForm.value)">
                    <mat-form-field>
                        <mat-label>Username</mat-label>
                        <input matInput placeholder="Username" formControlName="username" required />
                        <mat-error>Username required</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input
                            matInput
                            [type]="hidePassword ? 'password' : 'text'"
                            placeholder="Enter Password"
                            formControlName="password"
                            required
                        />
                        <button
                            mat-icon-button
                            matSuffix
                            (click)="hidePassword = !hidePassword"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="!hidePassword"
                        >
                            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error>Password required</mat-error>
                    </mat-form-field>

                    <button mat-flat-button color="primary">Log in</button>
                    <matt-button class="forgot-password" [routerLink]="['/auth/reset-password']"
                        >Forgot Password?</matt-button
                    >
                </form>
            </mat-card-content>
        </article>
    </mat-card>
</div>

import { map, Observable, of } from 'rxjs';

import { AlphaKingApiCall } from '../api';

import { IResponse } from '../common.interface';

import { AlphakingSchemaModel, IAlphaKingSchema } from './schema.model';

export class SchemaServices {
    private _schemas: Array<any> = [];
    public get schemas(): Array<any> {
        return this._schemas;
    }

    constructor(private _api: AlphaKingApiCall) {}

    public getSchema(topic: string): Observable<IResponse<IAlphaKingSchema>> {
        return this._api.request<IAlphaKingSchema>({
            topic: topic,
            version: 'v1',
            method: 'GET',
            resource: '/schema',
        });
    }

    public getBrokerSchema(): Observable<any> {
        return this.getSchema('broker');
    }

    public getConfigSchema(): Observable<AlphakingSchemaModel> {
        return this.getSchema('config').pipe(
            map((resp: IResponse<IAlphaKingSchema>) => {
                return new AlphakingSchemaModel(resp.data);
            })
        );
    }

    public getNetworkConfigSchema(): Observable<AlphakingSchemaModel> {
        return this.getSchema('network').pipe(
            map((resp: IResponse<IAlphaKingSchema>) => {
                return new AlphakingSchemaModel(resp.data);
            })
        );
    }

    // TODO: Find out more from Corey regarding schema structure and arrays returned etc...
    // There was talk about returning one large array of schemas, forcing the consumer to then extract desired schema
    // from the array. For now assuming will be able to query schemas on a container basis
    public findConfigContainerSchema(container: string): AlphakingSchemaModel {
        return this._schemas.find((i) => i.$id === container);
    }
}

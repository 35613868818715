import { Component, Input } from '@angular/core';
import { DeviceInfoModel } from 'src/app/modules/core/api/config';

@Component({
    selector: 'app-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.scss'],
})
export class StorageComponent {
    // TODO: It was never decided where the storage usage was going to get stored, talk to Corey about this
    @Input() deviceInfo: DeviceInfoModel | null = null;
}

import { Observable, of, map, tap } from 'rxjs';

import { AlphaKingApiCall } from '../api';

import { IResponse } from '../common.interface';
import { RoleModel, IRole } from './role.model';
import { ISystemPermission, PermissionModel, SystemPermissionsModel } from './permission.model';
import { IPermission } from './permission.model';

export class RoleServices {
    constructor(private _apiService: AlphaKingApiCall) {}

    public getSystemPermissions(): Observable<SystemPermissionsModel[]> {
        return this._apiService
            .request<{ permissions: ISystemPermission[] }>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/system_permissions',
            })
            .pipe(
                map((resp: IResponse<{ permissions: ISystemPermission[] }>) => {
                    if (resp.status !== 200) {
                        throw new Error(`Error Occured of status type: ${resp.status}`);
                    }
                    if (!resp.data || !Array.isArray(resp.data.permissions)) {
                        throw new Error(`Unexpected response ${resp.data?.permissions}`);
                    }

                    const permissionsData = resp.data.permissions;
                    return permissionsData.map((data) => new SystemPermissionsModel(data));
                })
            );
    }

    public createRole(role: IRole): Observable<RoleModel> {
        return this._apiService
            .request<IRole>({
                topic: 'roles',
                version: 'v1',
                method: 'PUT',
                resource: '/api/settings/role',
                data: role,
            })
            .pipe(
                map((resp: IResponse<IRole>) => {
                    return new RoleModel(resp.data);
                })
            );
    }

    public getRoles(): Observable<Array<RoleModel>> {
        return this._apiService
            .request<{ roles: Array<IRole> }>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/roles',
            })
            .pipe(
                map((resp: IResponse<{ roles: Array<IRole> }>) => {
                    const ret: Array<RoleModel> = [];
                    resp.data?.roles?.map((i: IRole) => ret.push(new RoleModel(i)));
                    return ret;
                })
            );
    }

    public getRole(id: number): Observable<RoleModel> {
        return this._apiService
            .request<IRole>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/roles',
                data: { id: id },
            })
            .pipe(
                map((resp: IResponse<IRole>) => {
                    return new RoleModel(resp.data);
                })
            );
    }

    public updateRole(role: IRole): Observable<RoleModel> {
        return this._apiService
            .request({
                topic: 'roles',
                version: 'v1',
                method: 'PATCH',
                resource: '/api/settings/role',
                data: role,
            })
            .pipe(
                map((res: IResponse) => {
                    return new RoleModel(role);
                })
            );
    }

    public deleteRole(role: IRole): Observable<IResponse> {
        return this._apiService.request<IResponse>({
            topic: 'roles',
            version: 'v1',
            method: 'DELETE',
            resource: '/api/settings/role',
            data: role,
        });
    }

    public createPermission(permission: IPermission): Observable<PermissionModel> {
        return this._apiService
            .request<IPermission>({
                topic: 'roles',
                version: 'v1',
                method: 'PUT',
                resource: '/api/settings/role_permission',
                data: permission,
            })
            .pipe(
                map((resp: IResponse<IPermission>) => {
                    return new PermissionModel(resp.data);
                })
            );
    }

    public getPermission(id: number): Observable<PermissionModel> {
        return this._apiService
            .request<IPermission>({
                topic: 'roles',
                version: 'v1',
                method: 'GET',
                resource: '/api/settings/role_permission',
                data: {
                    id: id,
                },
            })
            .pipe(
                map((resp: IResponse<IPermission>) => {
                    return new PermissionModel(resp.data);
                })
            );
    }

    public updatePermission(permission: IPermission): Observable<PermissionModel> {
        return this._apiService
            .request({
                topic: 'roles',
                version: 'v1',
                method: 'PATCH',
                resource: '/api/settings/role',
                data: permission,
            })
            .pipe(
                map((res: IResponse) => {
                    return new PermissionModel(res.data);
                })
            );
    }

    public deletePermission(permission: IPermission): Observable<IResponse> {
        return this._apiService.request<IResponse>({
            topic: 'roles',
            version: 'v1',
            method: 'DELETE',
            resource: '/api/settings/role',
            data: permission,
        });
    }
}

import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AtlasIedWebsocketService, WEBSOCKETSTATUS } from '@atlasied/atlasied-websocket';

import { Event, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { environment } from './../environments/environment';

import { TranslateService } from '@ngx-translate/core';
import { AtlasIedThemeService, ITheme } from '@atlasied/themes';
import { SidebarService } from './modules/core/sidebar-service/sidebar-service';
import { _STATE_OPTIONS_ } from './modules/common/sidebar/sidebar.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    protected stateOptions = _STATE_OPTIONS_;
    private _sidebarState: _STATE_OPTIONS_ = _STATE_OPTIONS_.undefined;
    public get sidebarState(): _STATE_OPTIONS_ {
        return this._sidebarState;
    }

    // These routes will disable the header and mat-sidebar
    private _noLayoutRoutes = ['auth', 'login', 'register', 'password', 'reset-password'];
    protected currentBaseRoute: string | undefined;

    private _requiresLayout = true;

    public get requiresLayoutValue(): boolean {
        return this._requiresLayout;
    }

    public get translateService(): TranslateService {
        return this._translate;
    }

    public get noLayoutRoutes() {
        return this._noLayoutRoutes;
    }

    public get requiresLayout() {
        return this._requiresLayout;
    }

    public set requiresLayout(val: boolean) {
        this._requiresLayout = val;
    }

    title = 'AtlasIED';

    private _subs: Array<Subscription> = [];

    @HostListener('document:keydown.shift.control.d', ['$event']) onShowCustomerServiceRoute(ev: KeyboardEvent) {
        ev.preventDefault();
        this._router.navigate(['/customer-service', {}]);
    }

    constructor(
        private _router: Router,
        private _translate: TranslateService,
        private _themeService: AtlasIedThemeService,
        private _wss: AtlasIedWebsocketService,
        private _sidebarService: SidebarService
    ) {
        this._themeService.setThemes([
            {
                default: false,
                class: 'british-weather',
                name: 'British Weather',
            },
        ]);

        const theme = localStorage.getItem('theme');
        if (theme) {
            this._themeService.setCurrentTheme(theme);
        }
    }

    ngOnInit(): void {
        this._initRouterNavigationChange();
        this._initSidebarStateChange();

        this._wss.init(environment.wsServer);
    }

    ngOnDestroy(): void {
        this._subs.forEach((i) => i.unsubscribe());
    }

    private _needsLayoutComponent(url: string): boolean {
        let ret = true;
        const newUrl = url.split(';')[0];
        for (let i = 0; i < this._noLayoutRoutes.length; i++) {
            if (newUrl.endsWith(this._noLayoutRoutes[i])) {
                ret = false;
                break;
            }
        }

        return ret;
    }

    private _initRouterNavigationChange(): void {
        this._subs.push(
            this._router.events.subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                    this._requiresLayout = this._needsLayoutComponent(event.urlAfterRedirects);
                }
            })
        );
    }

    private _initSidebarStateChange(): void {
        this._sidebarService.stateChange$.subscribe((state: _STATE_OPTIONS_) => (this._sidebarState = state));
    }

    public getPrivateMethod(instance: any, methodName: string): Function {
        return instance[methodName];
    }
}

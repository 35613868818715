import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'priority',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/priority/priority.module').then((m) => m.PriorityModule),
    },
    {
        path: 'services',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/services/services.module').then((m) => m.ServicesModule),
    },
    {
        path: 'hardware',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/hardware/hardware.module').then((m) => m.HardwareModule),
    },
    {
        path: 'media',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/media/media.module').then((m) => m.MediaModule),
    },
    {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
        path: 'customer-service',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./routes/customer-service/customer-service.module').then((m) => m.CustomerServiceModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}

<div class="slider-input">
    <div class="slider-row">
        <mat-slider [max]="max" [min]="min" [step]="step" [showTickMarks]="showTicks" discrete>
            <input
                matSliderThumb
                [disabled]="false"
                [attr.aria-label]="value"
                [attr.aria-valuemin]="min"
                [attr.aria-valuemax]="max"
                [attr.aria-valuenow]="value"
                [(ngModel)]="value"
                (ngModelChange)="updateValue($event)"
                data-test-slider
            />
        </mat-slider>

        <mat-form-field>
            <mat-label *ngIf="label?.length">{{ label }}</mat-label>
            <input matInput type="number" [(ngModel)]="value" (blur)="onTouched()" data-test-slider-level />
        </mat-form-field>
    </div>
</div>

import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AtlasIedWebsocketService } from '@atlasied/atlasied-websocket';
import { DateTimeService } from '@atlasied/datetime';

import { environment } from 'src/environments/environment';
import { NavigationService } from './services/navigation/navigation.service';
import { LanguageService } from 'src/app/modules/shared/services/language/language.service';
import { FormControlPipe } from './helpers/pipes/form-control.pipe';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';
import { BytesSizePipe } from './directives/bytes-size.pipe';
import { FormatTimePipe } from './directives/format-time.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MaterialModule } from '@priority-events/shared/modules/material.module';
import { ServiceStatusFormatPipe } from './directives/service-status-format.pipe';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        HttpClientModule,
        MaterialModule,
    ],
    providers: [
        { provide: 'apiServer', useValue: environment.apiServer },
        HttpClient,
        AtlasIedWebsocketService,
        DateTimeService,
        NavigationService,
        LanguageService,
    ],
    declarations: [
        FormControlPipe,
        MatTableResponsiveDirective,
        BytesSizePipe,
        FormatTimePipe,
        ConfirmationModalComponent,
        ServiceStatusFormatPipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FormControlPipe,
        MatTableResponsiveDirective,
        TranslateModule,
        BytesSizePipe,
        FormatTimePipe,
        ServiceStatusFormatPipe,
        MaterialModule,
        ServiceStatusFormatPipe,
    ],
})
export class SharedModule {}

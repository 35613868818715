<div
    id="reset-password-page"
    *ngIf="{
        di: deviceInfoConfig$ | async,
        nc: networkConfig$ | async,
        wss: wsStatus$ | async
    } as data"
>
    <mat-card appearance="outlined" class="mat-card">
        <div id="content-container">
            <div class="content-text-container">
                <div class="header-text">
                    <h1>{{ data.di?.location }} {{ data.di?.name }}</h1>
                    <span id="ip-address">IP: {{ data.nc?.ip_address }}</span
                    ><span>MAC: {{ data.di?.formatted_mac_address }}</span>
                </div>
                <br />
                <span>
                    <img
                        class="device-img"
                        src="/assets/images/product/{{ data.di?.model }}.png"
                        alt="image not available"
                    />
                </span>
                <br />
                <div class="info-text">
                    <strong>IP-SDMCF2</strong>
                    <div>PoE Indoor Wall Mount IP Speaker with</div>
                    <div>LED Display, LED Flasher, Camera and</div>
                    <div>Talkback Microphone</div>
                </div>
            </div>
            <div class="divider">
                <mat-divider vertical="true"></mat-divider>
            </div>
            <div class="user-input-container">
                <div id="right-container">
                    <div>
                        <img class="logo-img" src="assets/images/atlasied-logo-no-bg.png" alt="Atlas|IED" />
                    </div>
                    <form
                        id="auth-form"
                        [formGroup]="resetPasswordForm"
                        novalidate
                        (ngSubmit)="onSubmitResetPasswordDetails(resetPasswordForm.value)"
                    >
                        <div class="form-container">
                            <mat-card-header>
                                <mat-card-title class="mat-card-title">Password Reset</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mat-card-content">
                                <mat-form-field class="full-width" *ngIf="!isPasswordEmailSent">
                                    <input matInput placeholder="Username" formControlName="username" required />
                                    <mat-error *ngFor="let validation of login_validation_messages.username">
                                        <mat-error
                                            class="error-message"
                                            *ngIf="
                                                resetPasswordForm?.get('username')?.hasError(validation.type) &&
                                                (resetPasswordForm?.get('username')?.dirty ||
                                                    resetPasswordForm?.get('username')?.touched)
                                            "
                                        >
                                            {{ validation.message }}</mat-error
                                        >
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="!isPasswordEmailSent" class="reset-email-text">
                                    Enter your username and click continue. A password reset email will sent to your
                                    registered email address.
                                </div>
                                <div *ngIf="isPasswordEmailSent" class="reset-email-text">
                                    A password reset email has been sent. Please follow the instructions to reset your
                                    password and then try loggin in.
                                </div>
                            </mat-card-content>
                            <mat-card-actions
                                [ngClass]="!isPasswordEmailSent ? 'mat-card-actions' : 'mat-card-actions-email-sent'"
                            >
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="btn-submit"
                                    [disabled]="!resetPasswordForm.valid"
                                >
                                    Continue
                                </button>
                            </mat-card-actions>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-card>
</div>

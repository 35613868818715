import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AtlasIedWebsocketService, WEBSOCKETSTATUS } from '@atlasied/atlasied-websocket';
import { Observable } from 'rxjs';
import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';
import { DeviceInfoModel, NetworkConfigModel } from 'src/app/modules/core/api/config';

@Component({
    selector: 'app-reset-pasword',
    templateUrl: './reset-pasword.component.html',
    styleUrls: ['./reset-pasword.component.scss'],
})
export class ResetPaswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    isPasswordEmailSent = false;
    protected deviceInfoConfig$: Observable<DeviceInfoModel> | undefined = undefined;
    protected networkConfig$: Observable<NetworkConfigModel> | undefined = undefined;
    protected wsStatus$: Observable<WEBSOCKETSTATUS> | undefined = undefined;
    protected wssEnum = WEBSOCKETSTATUS;

    login_validation_messages = {
        username: [{ type: 'required', message: 'Username is required' }],
    };

    constructor(
        private _fb: FormBuilder,
        private _api: AlphaKingApiService,
        private _router: Router,
        private _wsService: AtlasIedWebsocketService
    ) {
        this.resetPasswordForm = this._fb.group({
            username: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.deviceInfoConfig$ = this._api.configServices.getDeviceInfo();
        this.networkConfig$ = this._api.configServices.getNetworkConfig();
        this.wsStatus$ = this._wsService.socketStatus$;
    }

    onSubmitResetPasswordDetails(value: any) {
        if (value.username !== undefined && value.username !== '') {
            this.isPasswordEmailSent = true;
        }
    }
}

import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';

import { DeviceInfoModel } from 'src/app/modules/core/api/config';

@Component({
    selector: 'app-sidebar-locate',
    templateUrl: './sidebar-locate.component.html',
    styleUrls: ['./sidebar-locate.component.scss'],
})
export class SidebarLocateComponent {
    @Input() deviceInfo: DeviceInfoModel | null = null;

    constructor() {}

    onAudioLocate(): void {}

    onStrobeLocate(): void {}
}

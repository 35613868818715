import { Component, forwardRef, Input } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
    ValidationErrors,
    AbstractControl,
} from '@angular/forms';

@Component({
    selector: 'app-slider-input',
    templateUrl: './slider-input.component.html',
    styleUrls: ['./slider-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderInputComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SliderInputComponent),
            multi: true,
        },
    ],
})
export class SliderInputComponent implements ControlValueAccessor, Validator {
    @Input() min = 0;
    @Input() max = 100;
    @Input() step = 5;
    @Input() showTicks = true;
    @Input() label: string | undefined;
    @Input() thumbLabel = true;

    protected value = 0;
    protected onChange: (_: any) => void = () => {};
    protected onTouched: () => void = () => {};

    public writeValue(value: number): void {
        this.value = value;
    }

    public registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {}

    public updateValue(newValue: number): void {
        this.value = newValue;
        this.onChange(this.value);
        this.onTouched();
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (control.value < this.min || control.value > this.max) {
            return { rangeError: true };
        }
        return null;
    }
}

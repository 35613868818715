import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';
import { timer } from 'rxjs';
import { IWaveSurferConfig } from 'src/app/modules/shared/models/wave-surfer-config.model';
import WaveSurfer from 'wavesurfer.js';
import { formatAudioTime } from '../../shared/helpers/functions/helper-functions';
import { AudioAsset } from '../../shared/models/audio-asset.model';

@Component({
    selector: 'app-wave-surfer',
    templateUrl: './wave-surfer.component.html',
    styleUrls: ['./wave-surfer.component.scss'],
})
export class WaveSurferComponent implements AfterViewInit, OnDestroy {
    @Input() audioAssest: AudioAsset | undefined;
    private _waveColor = getComputedStyle(document.documentElement).getPropertyValue('--wave-color');
    private _progressColor = getComputedStyle(document.documentElement).getPropertyValue('--wave-progress-color');
    @Input() config: IWaveSurferConfig = {
        container: '#waveform',
        waveColor: this._waveColor,
        progressColor: this._progressColor,
        hideScrollbar: true,
        barHeight: 4,
        barGap: 4,
        autoCenter: true,
        barRadius: 2,
        responsive: true,
        displayAudioDuration: true,
        displayVolume: true,
        displayPlaybackRate: false,
        displayAudioControls: true,
        volume: 0.5,
        playbackRate: 1,
        mute: false,
        height: 70,
    };

    private _waveSurfer: WaveSurfer | undefined;
    public isPlaying = false;
    public currentTimePercentage = 0;

    waveSurferCreate = WaveSurfer.create;

    constructor(private _renderer: Renderer2, private _el: ElementRef) {}

    ngAfterViewInit(): void {
        timer(100).subscribe(() => {
            this._waveSurfer = this.waveSurferCreate(this.config);

            if (this.audioAssest?.file) {
                this._waveSurfer.load(this.audioAssest?.file);
            } else {
                this._waveSurfer.load('assets/sample-audio/PinkPanther30.wav');
            }

            if (this._waveSurfer) {
                this._waveSurfer?.on('ready', () => this.updateCurrentTimeDisplay());
                this._waveSurfer?.on('audioprocess', () => this.updateCurrentTimeDisplay());
                this._waveSurfer?.on('seek', () => this.updateCurrentTimeDisplay());
                this._waveSurfer?.on('finish', () => this._waveSurfer?.play());
            }
        });
    }

    public playPauseAudio(): void {
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
            this._waveSurfer?.play();
        } else {
            this._waveSurfer?.pause();
        }
    }

    public updateCurrentTimeDisplay(): void {
        const currentTimeElement = this._el.nativeElement.querySelector('.currentTime') as HTMLDivElement;
        const text = `${formatAudioTime(this._waveSurfer?.getCurrentTime())} / ${formatAudioTime(
            this._waveSurfer?.getDuration()
        )}`;

        if (currentTimeElement) {
            this._renderer.setProperty(currentTimeElement, 'innerText', text);
        }
    }

    public updatePlaybackRate(): void {
        this._waveSurfer?.setPlaybackRate(this.config.playbackRate);
    }

    public updateVolume(): void {
        this.config.mute = false;
        this._waveSurfer?.setVolume(this.config.volume);
        this._waveSurfer?.setMute(this.config.mute);
    }

    public skipPrevious(): void {
        this._waveSurfer?.skipBackward(2);
    }

    public skipNext(): void {
        this._waveSurfer?.skipForward(2);
    }

    public muteAudio() {
        this.config.mute = !this.config.mute;
        this._waveSurfer?.setMute(this.config.mute);
    }

    get waveSurfer(): WaveSurfer | undefined {
        return this._waveSurfer as WaveSurfer | undefined;
    }

    ngOnDestroy(): void {
        this._waveSurfer?.stop();
    }
}

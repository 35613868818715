export interface ISpeakerMicrophone {
    on_board_speaker_gain: number;
    secondary_speaker_gain: number;
    line_out_gain: number;
    multicast_out_gain: number;
    enable_local_audio_prompts: boolean;
    local_audio_prompts_gain: number;
    line_in_level: number;
    microphone_level: number;
}

export class SpeakerMicrophoneModel implements ISpeakerMicrophone {
    on_board_speaker_gain: number = 0;
    secondary_speaker_gain: number = 0;
    line_out_gain: number = 0;
    multicast_out_gain: number = 0;
    enable_local_audio_prompts: boolean = false;
    local_audio_prompts_gain: number = 0;
    line_in_level: number = 0;
    microphone_level: number = 0;

    constructor(obj?: ISpeakerMicrophone) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

<article class="card device-info" *ngIf="deviceInfo !== undefined && networkConfig !== undefined">
    <div class="list-item">
        <mat-icon class="material-icons-outlined">lan</mat-icon> IP {{ networkConfig?.ip_address }}
    </div>
    <div class="list-item">
        <mat-icon class="material-icons-outlined">alternate_email</mat-icon> MAC
        {{ deviceInfo?.formatted_mac_address | uppercase }}
    </div>
    <div class="list-item"><mat-icon class="material-icons-outlined">room</mat-icon>{{ deviceInfo?.location }}</div>
    <div class="list-item">
        <mat-icon class="material-icons-outlined">memory</mat-icon> V{{ deviceInfo?.firmware_version }}
    </div>
    <div class="list-item"><mat-icon class="material-icons-outlined">timer</mat-icon> {{ deviceInfo?.uptime }}</div>
</article>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { fadeInUpOnEnterAnimation } from 'angular-animations';
import { NetworkConfigModel } from 'src/app/modules/core/api/config';
import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';
import { InfoModel } from 'src/app/modules/core/api/models/info.model';

import { Container, Engine, ISourceOptions } from 'tsparticles-engine';
import { loadFull } from 'tsparticles';
import configs from 'tsparticles-demo-configs';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 1200, delay: 100, translate: '42px' })],
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public hidePassword = true;
    protected infoConfig$: Observable<InfoModel> | undefined = undefined;
    protected networkConfig$: Observable<NetworkConfigModel> | undefined = undefined;

    id = 'tsparticles';
    options: ISourceOptions = configs.chars;

    constructor(private _fb: FormBuilder, private _api: AlphaKingApiService, private _router: Router) {
        this.loginForm = this._fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });

        this.options = {
            fps_limit: 30,
            interactivity: {
                detect_on: 'canvas',
                events: {
                    onclick: { enable: false, mode: 'push' },
                    onhover: { enable: false, mode: 'repulse', parallax: { enable: false, force: 60, smooth: 10 } },
                    resize: true,
                },
                modes: {
                    bubble: { distance: 400, duration: 2, opacity: 0.8, size: 40, speed: 3 },
                    grab: { distance: 400, line_linked: { opacity: 1 } },
                    push: { particles_nb: 4 },
                    remove: { particles_nb: 2 },
                    repulse: { distance: 200, duration: 0.4 },
                },
            },
            particles: {
                color: { value: '#ffffff' },
                line_linked: { color: '#ffffff', distance: 150, enable: true, opacity: 0.8, width: 1 },
                move: {
                    attract: { enable: true, rotateX: 600, rotateY: 1200 },
                    bounce: false,
                    direction: 'none',
                    enable: true,
                    out_mode: 'bounce',
                    random: false,
                    speed: 0.75,
                    straight: false,
                },
                number: { density: { enable: true, value_area: 800 }, value: 32 },
                opacity: {
                    anim: { enable: true, opacity_min: 0.5, speed: 0.5, sync: false },
                    random: false,
                    value: 0.75,
                },
                shape: {
                    character: {
                        fill: true,
                        font: 'Roboto',
                        style: '',
                        value: ['AlphaKing', 'AtlasIED'],
                        weight: '400',
                    },
                    polygon: { nb_sides: 6 },
                    stroke: { color: '#ffffff', width: 1 },
                    type: 'char',
                },
                size: { anim: { enable: true, size_min: 6, speed: 10, sync: false }, random: false, value: 6 },
            },
            polygon: {
                draw: { enable: true, lineColor: '#ffffff', lineWidth: 0.5 },
                move: { radius: 10 },
                scale: 1,
                type: 'none',
                url: '',
            },
            retina_detect: true,
        };
    }

    ngOnInit(): void {
        this.infoConfig$ = this._api.api.info();
        this.networkConfig$ = this._api.configServices.getNetworkConfig();
    }

    public onSubmit(values: any): void {
        this._api.userServices.authenticate(values.username, values.password).subscribe({
            next: (data: any) => {
                sessionStorage.setItem('authToken', data.authToken);
                this._router.navigate(['/dashboard']);
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);
            },
        });
    }

    public get usernameHasError(): string {
        // TODO: Implement user feedback
        console.log('usernameHasError');
        return 'bollocks';
    }

    particlesLoaded(container: Container): void {}

    async particlesInit(engine: Engine): Promise<void> {
        await loadFull(engine);
    }
}

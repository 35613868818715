import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './modules/core/core.module';

import { SharedModule } from './modules/shared/shared.module';
import { CommonComponentsModule } from 'src/app/modules/common/common-components.module';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

// Routes
import { AuthModule } from './routes/auth/auth.module';
import { NotFoundModule } from './routes/not-found/not-found.module';

import { AuthGuard } from './auth-guard';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        CommonComponentsModule,
        // Route modules **********************************
        AppRoutingModule,
        AuthModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictActionSerializability: true,
                strictStateSerializability: true,
            },
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
        NotFoundModule, // Keep as last loaded route module
        // End route modules ******************************
    ],
    providers: [AuthGuard],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { _STATE_OPTIONS_ } from '../../common/sidebar/sidebar.component';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    private _stateChange$: BehaviorSubject<_STATE_OPTIONS_> = new BehaviorSubject<_STATE_OPTIONS_>(
        _STATE_OPTIONS_.undefined
    );
    public get stateChange$(): Observable<_STATE_OPTIONS_> {
        return this._stateChange$.asObservable();
    }

    constructor() {}

    public update(state: _STATE_OPTIONS_): void {
        this._stateChange$.next(state);
    }
}

// SCHEMA_VALIDATE: "$id": "response.roles.v1./api/settings/roles.GET"
import { PermissionModel } from './permission.model';

export interface IRole {
    id?: number;
    role_name: string | undefined;
    permissions: Array<PermissionModel> | undefined;
}

// Haha role model hahahahaha
export class RoleModel implements IRole {
    id?: number;
    role_name: string | undefined;
    permissions: Array<PermissionModel> | undefined;

    constructor(obj?: IRole) {
        this.id = obj?.id;
        this.role_name = obj?.role_name;
        this.permissions = obj?.permissions;
    }
}

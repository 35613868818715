<header>
    <app-sidenav #sidenav [state]="'close'"></app-sidenav>

    <div>
        <button
            class="toggler mat-mini-fab"
            matTooltip="{{ 'header.toggleSidebar' | translate }}"
            mat-icon-button
            (click)="sidenav.open()"
        >
            <mat-icon>menu</mat-icon>
        </button>
        <div class="logo">
            <img src="assets/images/atlasied-logo-sm-white.png" alt="Atlas|IED" />
        </div>

        <nav
            mat-tab-nav-bar
            [backgroundColor]="'primary'"
            [tabPanel]="tabPanel"
            [disablePagination]="true"
            [fitInkBarToContent]="true"
        >
            <a
                mat-tab-link
                *ngFor="let n of _navService.getNavigation()"
                (click)="_activeLink = n.routerLink"
                [routerLink]="[n.routerLink]"
                [active]="_activeLink === n.routerLink"
                data-title="{{ n.title | translate }}"
            >
                {{ n.title | translate }}
            </a>
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

        <div class="actions">
            <button
                id="theme-menu-trigger"
                [matMenuTriggerFor]="themeMenu"
                mat-mini-fab
                matTooltip="{{ 'header.themeTooltip' | translate }}"
            >
                <mat-icon>format_color_fill</mat-icon>
            </button>

            <mat-menu #themeMenu="matMenu" overlayPanelClass="theme-overlay-panel">
                <ng-container *ngFor="let i of _themeService.getThemes()">
                    <button class="theme-{{ i.class }}" mat-menu-item (click)="onChangeTheme(i)">
                        <span>{{ i.name }}</span>
                    </button>
                </ng-container>
            </mat-menu>

            <button
                id="language-menu-trigger"
                [matMenuTriggerFor]="languageMenu"
                mat-mini-fab
                matTooltip="{{ 'header.languageTooltip' | translate }}"
            >
                <mat-icon>language</mat-icon>
            </button>

            <mat-menu #languageMenu="matMenu" overlayPanelClass="language-overlay-panel">
                <ng-container *ngFor="let i of _languageService.getLanguages()">
                    <button class="language-{{ i.key }}" mat-menu-item (click)="onLanguageChange(i)">
                        <span>{{ i.value }}</span>
                    </button>
                </ng-container>
            </mat-menu>

            <button
                id="user-menu-trigger"
                [matMenuTriggerFor]="userMenu"
                mat-mini-fab
                matTooltip="{{ 'header.userTooltip' | translate }}"
            >
                JT
            </button>

            <mat-menu #userMenu="matMenu" overlayPanelClass="user-overlay-panel">
                <button mat-menu-item [routerLink]="'user'">
                    <mat-icon>settings</mat-icon><span></span>{{ 'header.userProfile' | translate }}
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon>logout</mat-icon><span>{{ 'header.userLogout' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</header>

<div class="top-spacer"></div>

import { map, Observable, of } from 'rxjs';

import { AlphaKingApiCall } from '../api';

import { DeviceInfoModel, IDeviceInfo } from './device-info.model';
import { NetworkConfigModel, INetworkConfig } from './network.model';
import { InformacastConfigModel, IInformacastConfig } from './informacast-config.model';
import { GckConfigModel, IGckConfig } from './gck-config.model';
import { IRevolutionConfig, RevolutionConfigModel } from './revolution-service.model';
import { ISipConfig, SipConfigModel } from './sip-config.model';
import { IRemoteLoggingConfig, RemoteLoggingConfigModel } from './remote-syslog.model';

import { IResponse } from '../common.interface';
import { AudioAssetModel, IAudioAsset } from './audio-asset.model';
import { IResultsConfig, ResultsModel } from './results.model';
import { ITimeConfig, TimeConfigModel } from './time-config.model';
import { IIpxSceneModel, IpxSceneModel } from './ipx-scens.model';
import { SpeakerMicrophoneModel, ISpeakerMicrophone } from './speaker-microphone.model';
import { DisplayFormModel, IDisplayForm } from './display-info.model';
import { LightBarConfigModel, ILightBarConfig } from './light-bar.model';

export class ConfigServices {
    constructor(private _api: AlphaKingApiCall) {}

    public getConfig<T = any>(containerName: string): Observable<IResponse<T>> {
        return this._api
            .request<any>({
                topic: 'config',
                version: 'v1',
                method: 'GET',
                resource: `/api/settings/config/${containerName}`,
            })
            .pipe(
                map((resp: IResponse) => {
                    resp.data = resp.data[containerName];
                    return resp;
                })
            );
    }

    public subscribeConfig<T = any>(containerName: string): Observable<IResponse<T>> {
        return this._api
            .request<any>({
                topic: 'config_changes',
                version: 'v1',
                method: 'GET',
                resource: `/api/settings/config/${containerName}`,
            })
            .pipe(
                map((resp: IResponse) => {
                    resp.data = resp.data[containerName];
                    return resp;
                })
            );
    }

    public patchConfig<T = any>(config: T, containerName: string): Observable<IResponse<T>> {
        return this._api.request<T>({
            topic: 'config',
            version: 'v1',
            method: 'PATCH',
            resource: `/api/settings/config/${containerName}`,
            data: {
                [containerName]: config,
            },
        });
    }

    public getDeviceInfo(): Observable<DeviceInfoModel> {
        return this.getConfig<IDeviceInfo>('device').pipe(
            map((resp: IResponse<IDeviceInfo>) => {
                return new DeviceInfoModel(resp.data);
            })
        );
    }

    public subscribeDeviceInfo(): Observable<DeviceInfoModel> {
        return this.subscribeConfig<IDeviceInfo>('device').pipe(
            map((resp: IResponse<IDeviceInfo>) => {
                return new DeviceInfoModel(resp.data);
            })
        );
    }
    // TODO: When patchConfig is merged
    // public patchDeviceInfo(): Observable<DeviceInfoModel> {
    //     return this.getConfig<IDeviceInfo>('device');
    // }

    public getNetworkConfig(): Observable<NetworkConfigModel> {
        return this.getConfig<INetworkConfig>('network').pipe(
            map((resp: IResponse<INetworkConfig>) => {
                return new NetworkConfigModel(resp.data);
            })
        );
    }

    public getInformacastConfig(): Observable<InformacastConfigModel> {
        return this.getConfig<IInformacastConfig>('informacast').pipe(
            map((resp: IResponse<IInformacastConfig>) => {
                return new InformacastConfigModel(resp.data);
            })
        );
    }

    public getGckConfig(): Observable<GckConfigModel> {
        return this.getConfig<IGckConfig>('gck').pipe(
            map((resp: IResponse<IGckConfig>) => {
                return new GckConfigModel(resp.data);
            })
        );
    }

    public getRevolutionConfig(): Observable<RevolutionConfigModel> {
        return this.getConfig<IRevolutionConfig>('revolution').pipe(
            map((resp: IResponse<IRevolutionConfig>) => {
                return new RevolutionConfigModel(resp.data);
            })
        );
    }

    public getSipConfig(): Observable<SipConfigModel> {
        return this.getConfig<ISipConfig>('sip').pipe(
            map((resp: IResponse<ISipConfig>) => {
                return new SipConfigModel(resp.data);
            })
        );
    }

    public getRemoteSysConfig(): Observable<RemoteLoggingConfigModel> {
        return this.getConfig<IRemoteLoggingConfig>('remote_logging').pipe(
            map((resp: IResponse<IRemoteLoggingConfig>) => {
                return new RemoteLoggingConfigModel(resp.data);
            })
        );
    }

    public getAudioMessages(): Observable<Array<AudioAssetModel>> {
        return this.getConfig<Array<IAudioAsset>>('audio_messages').pipe(
            map((resp: IResponse<Array<IAudioAsset>>) => {
                if (resp.data) {
                    const audioAssets = resp.data.map((audioAsset) => new AudioAssetModel(audioAsset));
                    return audioAssets;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getResults(): Observable<ResultsModel> {
        return this.getConfig<IResultsConfig>('hardware_test_results').pipe(
            map((resp: IResponse<IResultsConfig>) => {
                if (resp.data) {
                    const results = new ResultsModel(resp.data);
                    return results;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getClockData(): Observable<TimeConfigModel> {
        return this.getConfig<ITimeConfig>('time').pipe(
            map((resp: IResponse<ITimeConfig>) => {
                if (resp.data) {
                    const results = new TimeConfigModel(resp.data);
                    return results;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getSpeakerMicrophoneSettings(): Observable<SpeakerMicrophoneModel> {
        return this.getConfig<ISpeakerMicrophone>('levels').pipe(
            map((resp: IResponse<ISpeakerMicrophone>) => {
                if (resp.data) {
                    const results = new SpeakerMicrophoneModel(resp.data);
                    return results;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getIpxScenes(): Observable<Array<IpxSceneModel>> {
        return this.getConfig<Array<IIpxSceneModel>>('ipx_scene_settings').pipe(
            map((resp: IResponse<Array<IIpxSceneModel>>) => {
                if (resp.data) {
                    const ipxSceneModels = resp.data.map((ipxScene) => new IpxSceneModel(ipxScene));
                    return ipxSceneModels;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public deleteIpxScene(id: number): Observable<{ status: number; message: string }> {
        return of(this.createMockResponse(200));
    }

    public getDisplaySettings(): Observable<DisplayFormModel> {
        return this.getConfig<IDisplayForm>('screen_dimming').pipe(
            map((resp: IResponse<IDisplayForm>) => {
                if (resp.data) {
                    const results = new DisplayFormModel(resp.data);
                    return results;
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getLightBarConfig(): Observable<LightBarConfigModel> {
        return this.getConfig<ILightBarConfig>('light_bar').pipe(
            map((resp: IResponse<ILightBarConfig>) => {
                if (resp.data) {
                    return new LightBarConfigModel(resp.data);
                } else {
                    throw new Error('Invalid response: data may be undefined.');
                }
            })
        );
    }

    public getMockThumbnail(url: string): Observable<{ status: number; message: string; url: string }> {
        const status = 200; // You can change this to the desired status code for the mock response
        const mockLargerThumbnail = url.replace(/\d+x\d+/, '500x500');
        const mockResponse = this.createMockResponse(status);
        return of({ ...mockResponse, url: mockLargerThumbnail });
    }

    public createMockResponse(status: number): { status: number; message: string } {
        let message: string;
        switch (status) {
            case 200:
                message = 'OK';
                break;
            case 201:
                message = 'Created';
                break;
            case 204:
                message = 'No Content';
                break;
            case 400:
                message = 'Bad Request';
                break;
            case 401:
                message = 'Unauthorized';
                break;
            case 403:
                message = 'Forbidden';
                break;
            case 404:
                message = 'Not Found';
                break;
            case 500:
                message = 'Internal Server Error';
                break;
            default:
                message = 'Unknown Status';
                break;
        }
        return { status: status, message: message };
    }
}

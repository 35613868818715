import { ChangeDetectionStrategy, Component, OnInit, Signal, ViewEncapsulation } from '@angular/core';

import { merge, Observable, first, of } from 'rxjs';

import { AtlasIedWebsocketService } from '@atlasied/atlasied-websocket';
import { SidebarService } from 'src/app/modules/core/sidebar-service/sidebar-service';

import { DeviceInfoModel, NetworkConfigModel } from 'src/app/modules/core/api/config';
import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';
import { ServicesStatusService } from '../../core/api/status/services-status.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ServiceStatusModel } from '../../shared/models/service-status.model';

export enum _STATE_OPTIONS_ {
    undefined,
    pinned,
    hovered,
    collapsed,
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
    protected stateOptions = _STATE_OPTIONS_;

    private _state: _STATE_OPTIONS_ = _STATE_OPTIONS_.undefined;

    public get state(): _STATE_OPTIONS_ {
        return this._state;
    }
    public set state(value: _STATE_OPTIONS_) {
        this._state = value;
        this._sidebarService.update(this._state);
    }

    protected serviceStatuses = toSignal(this._statusService.getServicesStatuses()) as Signal<ServiceStatusModel>;

    protected deviceInfo$: Observable<DeviceInfoModel> | undefined = undefined;
    protected networkConfig$: Observable<NetworkConfigModel> | undefined = undefined;

    constructor(
        private _api: AlphaKingApiService,
        protected _wsService: AtlasIedWebsocketService,
        private _sidebarService: SidebarService,
        private _statusService: ServicesStatusService
    ) {}

    ngOnInit(): void {
        this.state = _STATE_OPTIONS_.pinned;
        this.deviceInfo$ = merge(
            this._api.configServices.getDeviceInfo().pipe(first()),
            this._api.configServices.subscribeDeviceInfo()
        );

        this.networkConfig$ = this._api.configServices.getNetworkConfig();
    }

    public onToggleSidebar(): void {
        if (this.state === this.stateOptions.pinned) {
            this.state = this.stateOptions.collapsed;
        } else {
            this.state = this.stateOptions.pinned;
        }

        this._sidebarService.update(this.state);
    }

    public onMouseEnterContainer(ev: MouseEvent) {
        if (this.state === this.stateOptions.collapsed) {
            this.state = this.stateOptions.hovered;
        }
    }

    public onMouseLeaveSidebar(ev: MouseEvent) {
        if (this.state === this.stateOptions.hovered) {
            this.state = this.stateOptions.collapsed;
        }
    }
}

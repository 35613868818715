// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/light_bar.GET"
import { ColorRGBAModel, IColorRGBA } from './color-rgba.model';

export interface ILightBarConfig {
    mode?: string;
    color?: IColorRGBA;
    brightness?: number;
}

export class LightBarConfigModel implements ILightBarConfig {
    mode: string | undefined;
    color: ColorRGBAModel | undefined;
    brightness: number | undefined;

    constructor(obj?: ILightBarConfig) {
        this.mode = obj?.mode;
        this.color = obj?.color;
        this.brightness = obj?.brightness;
    }
}

<nav [ngClass]="state" class="side-nav mat-elevation-z10">
    <div>Main Menu <mat-icon class="menu-header" data-test-menu-header (click)="close()">close</mat-icon></div>

    <ng-container *ngFor="let n of _navService.getNavigation()">
        <a
            (click)="close()"
            [routerLink]="[n.routerLink]"
            [routerLinkActive]="['active']"
            data-title="{{ n.title | translate }}"
        >
            {{ n.title | translate }}
        </a>
    </ng-container>
</nav>

<article class="card device-locate" *ngIf="deviceInfo">
    <h1>{{ deviceInfo.location }} {{ deviceInfo.name }}</h1>

    <img src="/assets/images/product/{{ deviceInfo.model }}.png" />

    <h4>{{ deviceInfo.model }}</h4>

    <button
        *ngIf="deviceInfo.hasSpeaker()"
        class="audio-locate"
        data-test-audio-locate
        mat-stroked-button
        (click)="onAudioLocate()"
    >
        <mat-icon class="material-icons-outlined">volume_up</mat-icon>
        {{ 'sidebar.startLocating' | translate }}
    </button>
    <button
        *ngIf="deviceInfo.hasStrobe()"
        class="strobe-locate"
        data-test-strobe-locate
        mat-stroked-button
        (click)="onStrobeLocate()"
    >
        <mat-icon class="material-icons-outlined">lightbulb</mat-icon>
        {{ 'sidebar.startLocating' | translate }}
    </button>
</article>

<div
    id="app-sidebar"
    [class.pinned]="state === stateOptions.pinned"
    [class.collapsed]="state === stateOptions.collapsed"
    [class.hovered]="state === stateOptions.hovered"
    (mouseleave)="onMouseLeaveSidebar($event)"
>
    <div class="hover-zone">
        <div class="spacer"></div>
        <mat-divider [vertical]="true"></mat-divider>

        <a href="javascript://" class="sidebar-toggle inside" (click)="onToggleSidebar()" tabindex="-1">
            <mat-icon tabindex="-1">
                <span *ngIf="state === stateOptions.pinned">chevron_left</span>
                <span *ngIf="state !== stateOptions.pinned">chevron_right</span>
            </mat-icon>
        </a>
    </div>

    <div class="container" (mouseenter)="onMouseEnterContainer($event)">
        <div class="content">
            <app-sidebar-locate [deviceInfo]="deviceInfo$ | async"></app-sidebar-locate>

            <mat-divider></mat-divider>

            <app-service-status
                [socketStatus]="_wsService.socketStatus$ | async"
                [serviceStatus]="serviceStatuses()"
            ></app-service-status>

            <mat-divider></mat-divider>

            <app-device-info
                [deviceInfo]="deviceInfo$ | async"
                [networkConfig]="networkConfig$ | async"
            ></app-device-info>

            <mat-divider></mat-divider>

            <app-storage [deviceInfo]="deviceInfo$ | async"></app-storage>
        </div>
    </div>
</div>

export interface IRemoteLoggingConfig {
    enable: boolean | undefined;
    remote_host: string | undefined;
    remote_port: string | undefined;
}

export class RemoteLoggingConfigModel implements IRemoteLoggingConfig {
    enable: boolean | undefined;
    remote_host: string | undefined;
    remote_port: string | undefined;

    constructor(obj?: IRemoteLoggingConfig) {
        this.enable = obj?.enable;
        this.remote_host = obj?.remote_host;
        this.remote_port = obj?.remote_port;
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ServiceStatusOption } from 'src/app/modules/shared/models/service-status.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-sidebar-status-item',
    templateUrl: './sidebar-status-item.component.html',
    styleUrls: ['./sidebar-status-item.component.scss'],
})
export class SidebarStatusItemComponent implements OnInit {
    @Input() status: ServiceStatusOption | undefined = undefined;
    @Input() name: string | undefined = undefined;

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    ngOnInit() {
        this.matIconRegistry.addSvgIcon(
            'disabled',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/hide_source.svg')
        );
    }
}

<article class="wave-container">
    <div id="waveform"></div>

    <div id="user-controls">
        <div *ngIf="config.displayAudioDuration" class="currentTime"></div>

        <div *ngIf="this.config.displayAudioControls" class="controls">
            <mat-icon (click)="skipPrevious()">skip_previous</mat-icon>
            <mat-icon (click)="playPauseAudio()">{{ isPlaying ? 'pause' : 'play_circle' }}</mat-icon>
            <mat-icon (click)="skipNext()">skip_next</mat-icon>
        </div>

        <div *ngIf="config.displayVolume" class="volume">
            <mat-icon (click)="muteAudio()">{{ !config.mute ? 'volume_up' : 'volume_off' }}</mat-icon>
            <mat-slider *ngIf="config.displayVolume" min="0" max="1" step="0.1" (ngModelChange)="updateVolume()"
                ><input matSliderThumb [(ngModel)]="config.volume"
            /></mat-slider>
        </div>
    </div>
</article>

<div *ngIf="requiresLayout; else noLayout">
    <app-header></app-header>

    <section id="app-content">
        <app-sidebar></app-sidebar>

        <main [class.sidebar-pinned]="sidebarState === stateOptions.pinned">
            <router-outlet></router-outlet>
        </main>
    </section>
</div>

<ng-template #noLayout>
    <router-outlet></router-outlet>
</ng-template>

<div style="display: none" class="test-language">{{ 'test.text' | translate }}</div>

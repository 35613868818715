import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AtlasIedWebsocketService } from '@atlasied/atlasied-websocket';
import { Observable } from 'rxjs';

import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';
import { DeviceInfoModel, NetworkConfigModel } from 'src/app/modules/core/api/config';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
    protected restPasswordForm: FormGroup;
    protected deviceInfoConfig$: Observable<DeviceInfoModel> | undefined = undefined;
    protected networkConfig$: Observable<NetworkConfigModel> | undefined = undefined;

    // TODO: Camel case preferred, and no hard coded text. All text must come from the langiuage files
    // so translation can be performed. Please look for language pipe in the code or tests for an example
    resetPassword_validation_messages = {
        newPassword: [{ type: 'required', message: 'Password is required' }],
        confirmPassword: [{ type: 'required', message: 'Confirm pasword is required' }],
    };

    constructor(
        private _fb: FormBuilder,
        private _api: AlphaKingApiService,
        private _wsService: AtlasIedWebsocketService
    ) {
        this.restPasswordForm = this._fb.group({
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.deviceInfoConfig$ = this._api.configServices.getDeviceInfo();
        this.networkConfig$ = this._api.configServices.getNetworkConfig();
    }

    submit() {}

    // TODO: Function name is a bit long!
    onSubmitResetPasswordDetails(value: any) {
        // TODO: Implement user feedback
        console.log(value);
    }
}

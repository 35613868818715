import { Injectable } from '@angular/core';

export interface ILanguageDisplay {
    key: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly _languageDisplay: Array<ILanguageDisplay> = [
        {
            key: 'en',
            value: 'English',
        },
        {
            key: 'es',
            value: 'Español',
        },
        {
            key: 'fr',
            value: 'Français',
        },
        {
            key: 'de',
            value: 'Deutsche',
        },
    ];

    constructor() {}

    getLanguages(): Array<ILanguageDisplay> {
        return this._languageDisplay;
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServiceStatusModel } from '../../../shared/models/service-status.model';

const headers = new HttpHeaders({
    Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
});

@Injectable({
    providedIn: 'root',
})
export class ServicesStatusService {
    apiUrl = environment.apiServer;

    constructor(private http: HttpClient) {}

    public getServicesStatuses() {
        return this.http.get<ServiceStatusModel>(`${this.apiUrl}/status`, { headers });
    }
}

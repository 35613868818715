<div
    id="password-page"
    *ngIf="{
        di: deviceInfoConfig$ | async,
        nc: networkConfig$ | async
    } as data"
>
    <mat-card appearance="outlined" class="mat-card">
        <div id="content-container">
            <div class="content-text-container">
                <div class="header-text">
                    <h1>{{ data.di?.location }} {{ data.di?.name }}</h1>
                    <span id="ip-address">IP: {{ data.nc?.ip_address }}</span
                    ><span>MAC: {{ data.di?.formatted_mac_address }}</span>
                </div>
                <br />
                <span>
                    <img
                        class="device-img"
                        src="/assets/images/product/{{ data.di?.model }}.png"
                        alt="image not available"
                    />
                </span>
                <br />
                <div class="info-text">
                    <strong>{{ data.di?.model }}</strong>
                    <div>PoE Indoor Wall Mount IP Speaker with</div>
                    <div>LED Display, LED Flasher, Camera and</div>
                    <div>Talkback Microphone</div>
                </div>
            </div>
            <div class="divider">
                <mat-divider vertical="true"></mat-divider>
            </div>
            <div class="user-input-container">
                <div id="right-container">
                    <div>
                        <img class="logo-img" src="assets/images/atlasied-logo-no-bg.png" alt="Atlas|IED" />
                    </div>
                    <form
                        id="auth-form"
                        [formGroup]="restPasswordForm"
                        novalidate
                        (ngSubmit)="onSubmitResetPasswordDetails(restPasswordForm.value)"
                    >
                        <div class="form-container">
                            <mat-card-header>
                                <mat-card-title class="mat-card-title"
                                    >Welcome, since this is the first time you are logging in please create a new
                                    passsword
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mat-card-content">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        type="password"
                                        placeholder="New Password"
                                        formControlName="newPassword"
                                        required
                                    />
                                    <mat-error *ngFor="let validation of resetPassword_validation_messages.newPassword">
                                        <mat-error
                                            class="error-message"
                                            *ngIf="
                                                restPasswordForm?.get('newPassword')?.hasError(validation.type) &&
                                                (restPasswordForm?.get('newPassword')?.dirty ||
                                                    restPasswordForm?.get('newPassword')?.touched)
                                            "
                                        >
                                            {{ validation.message }}</mat-error
                                        >
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="full-width" id="password">
                                    <input
                                        matInput
                                        type="password"
                                        placeholder="Confirm password"
                                        formControlName="confirmPassword"
                                        required
                                    />
                                    <mat-error
                                        *ngFor="let validation of resetPassword_validation_messages.confirmPassword"
                                    >
                                        <mat-error
                                            class="error-message"
                                            *ngIf="
                                                restPasswordForm?.get('confirmPassword')?.hasError(validation.type) &&
                                                (restPasswordForm?.get('confirmPassword')?.dirty ||
                                                    restPasswordForm?.get('confirmPassword')?.touched)
                                            "
                                        >
                                            {{ validation.message }}</mat-error
                                        >
                                    </mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-actions>
                                <button
                                    mat-raised-button
                                    color="primary"
                                    class="btn-submit"
                                    [disabled]="!restPasswordForm.valid"
                                >
                                    Continue
                                </button>
                            </mat-card-actions>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-card>
</div>

export interface IIpxSceneModel {
    id: number;
    name: string;
    date_created: Date;
    size: number;
    thumbnail: string;
    scene_data: string;
    file_size: number;
}

export class IpxSceneModel implements IIpxSceneModel {
    id: number;
    name: string;
    date_created: Date;
    size: number;
    thumbnail: string;
    scene_data: string;
    file_size: number;

    constructor(obj: IIpxSceneModel) {
        this.id = obj.id;
        this.name = obj.name;
        this.date_created = obj.date_created;
        this.size = obj.size;
        this.thumbnail = obj.thumbnail;
        this.scene_data = obj.scene_data;
        this.file_size = obj.file_size;
    }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AlphaKingApiCall } from './api';
import { UserServices } from './user/user-services';
import { SchemaServices } from './schema/schema-services';
import { ConfigServices } from './config/config-services';
import { RoleServices } from './role';

@Injectable({
    providedIn: 'root',
})
export class AlphaKingApiService {
    public api: AlphaKingApiCall;
    public schemaServices: SchemaServices;
    public userServices: UserServices;
    public configServices: ConfigServices;
    public roleServices: RoleServices;

    constructor(@Inject('apiServer') public apiServer: string, private _http: HttpClient) {
        this.api = new AlphaKingApiCall(apiServer, _http);
        this.userServices = new UserServices(this.api, this.apiServer, _http);
        this.schemaServices = new SchemaServices(this.api);
        this.configServices = new ConfigServices(this.api);
        this.roleServices = new RoleServices(this.api);

        this.api.setUserObservable(this.userServices.currentUser$);
    }
}

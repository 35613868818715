<article class="card device-connection-status">
    <div class="list-item" *ngIf="socketStatus === wssEnum.OPEN">
        <mat-icon class="connected">check_circle_outline</mat-icon>Connected
    </div>
    <div class="list-item" *ngIf="socketStatus === wssEnum.CLOSED">
        <mat-icon class="disconnected material-icons-outlined">check_circle_outline</mat-icon>Not Connected
    </div>
    <div class="list-item error" *ngIf="socketStatus === wssEnum.ERROR">
        <mat-icon color="warn" class="material-icons-outlined">highlight_off</mat-icon>Error Connecting
    </div>
</article>

<article class="card device-services" *ngIf="serviceStatus">
    <app-sidebar-status-item [status]="serviceStatus.informacast" name="Informacast"></app-sidebar-status-item>
    <app-sidebar-status-item [status]="serviceStatus.gck" name="GCK"></app-sidebar-status-item>
    <app-sidebar-status-item [status]="serviceStatus.revolution" name="Revolution"></app-sidebar-status-item>
    <app-sidebar-status-item [status]="serviceStatus.sip" name="SIP"></app-sidebar-status-item>
</article>

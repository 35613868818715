import { Component, Input, OnInit } from '@angular/core';
import { WEBSOCKETSTATUS } from '@atlasied/atlasied-websocket';
import { noop } from 'rxjs';

import { ServiceStatusModel } from 'src/app/modules/shared/models/service-status.model';

@Component({
    selector: 'app-service-status',
    templateUrl: './service-status.component.html',
    styleUrls: ['./service-status.component.scss'],
})
export class ServiceStatusComponent implements OnInit {
    protected wssEnum = WEBSOCKETSTATUS;
    @Input() socketStatus: WEBSOCKETSTATUS | null = null;
    @Input() serviceStatus: ServiceStatusModel | null = null;

    constructor() {}

    ngOnInit() {
        noop();
    }
}

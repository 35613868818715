import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AlphaKingApiService } from 'src/app/modules/core/api/alphaking-api.service';
import { AtlasIedThemeService, ITheme } from '@atlasied/themes';
import { TranslateService } from '@ngx-translate/core';

import { ILanguageDisplay, LanguageService } from 'src/app/modules/shared/services/language/language.service';
import { NavigationService, INavigation } from 'src/app/modules/shared/services/navigation/navigation.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    protected _navigation: Array<INavigation> = [];
    protected _languages: ILanguageDisplay[] = [];
    protected _activeLink: string | undefined;

    constructor(
        private _api: AlphaKingApiService,
        protected _router: Router,
        protected _navService: NavigationService,
        protected _languageService: LanguageService,
        protected _themeService: AtlasIedThemeService,
        protected _translationService: TranslateService,
        private _location: Location
    ) {}

    ngOnInit(): void {
        this._activeLink = '/' + this._location.path().split('/')[1];
    }

    public logOut(): void {
        const authToken = sessionStorage.getItem('authToken') as string;
        this._api.userServices.logOut(authToken).subscribe({
            next: () => {
                sessionStorage.removeItem('authToken');
                this._router.navigate(['/auth']);
            },
            error: (err: HttpErrorResponse) => {
                // TODO: Implement user feedback
                console.log('api error', err);
            },
        });
    }

    public onChangeTheme(theme: ITheme): void {
        this._themeService.setCurrentTheme(theme.name);
        localStorage.setItem('theme', theme.name);
    }

    public onLanguageChange(lang: ILanguageDisplay): void {
        this._translationService.use(lang.key);
        localStorage.setItem('language', lang.key);
    }
}

<div *ngIf="status === 'registered'" class="list-item">
    <mat-icon class="connected">check_circle_outline</mat-icon>{{ name }}<mat-icon>chevron_right</mat-icon>
</div>

<div *ngIf="status === 'not_registered'" class="list-item">
    <mat-icon class="warn">error_outline</mat-icon>{{ name }}<mat-icon>chevron_right</mat-icon>
</div>

<div *ngIf="status === 'registration_lost'" class="list-item">
    <mat-icon class="warn">highlight_off</mat-icon>{{ name }}<mat-icon>chevron_right</mat-icon>
</div>

<div *ngIf="status === 'disabled'" class="list-item">
    <mat-icon svgIcon="disabled"></mat-icon>{{ name }}<mat-icon>chevron_right</mat-icon>
</div>

// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/network.GET"
export interface INetworkConfig {
    hostname: string | undefined;
    mac_address: string | undefined;
    ip_address: string | undefined;
    subnet_mask: string | undefined;
    gateway: string | undefined;
    dns_servers: Array<string> | undefined;
    dhcp: boolean | undefined;
    dhcp_timeout: number | undefined;
    vlan_id: number | undefined;
    vlan_priority: number | undefined;
    vlan_tagged: boolean | undefined;
}

export class NetworkConfigModel implements INetworkConfig {
    hostname: string | undefined;
    mac_address: string | undefined;
    ip_address: string | undefined;
    subnet_mask: string | undefined;
    gateway: string | undefined;
    dns_servers: Array<string> | undefined;
    dhcp: boolean | undefined;
    dhcp_timeout: number | undefined;
    vlan_id: number | undefined;
    vlan_priority: number | undefined;
    vlan_tagged: boolean | undefined;

    constructor(obj?: INetworkConfig) {
        this.hostname = obj?.hostname;
        this.mac_address = obj?.mac_address;
        this.ip_address = obj?.ip_address;
        this.subnet_mask = obj?.subnet_mask;
        this.gateway = obj?.gateway;
        this.dns_servers = obj?.dns_servers;
        this.dhcp = obj?.dhcp;
        this.dhcp_timeout = obj?.dhcp_timeout;
        this.vlan_id = obj?.vlan_id;
        this.vlan_priority = obj?.vlan_priority;
        this.vlan_tagged = obj?.vlan_tagged;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, Router, RouterModule } from '@angular/router';

import { LoginComponent, PasswordComponent, ResetPaswordComponent } from './pages';

const routes: Routes = [
    {
        path: 'auth',
        children: [
            { path: '', component: LoginComponent },
            { path: 'login', component: LoginComponent },
            { path: 'password', component: PasswordComponent },
            { path: 'reset-password', component: ResetPaswordComponent },
        ],
    },
];
@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
})
export class AuthRoutingModule {}

<app-back-button [key]="backButtonText" *ngIf="enableBackButton"></app-back-button>

<article>
    <mat-card appearance="outlined">
        <div class="card-section-header">
            <h1>
                {{ title }}
                @if(requiresTrademark){
                <span>&trade;</span>
                }
            </h1>

            <div *ngIf="enableActions" class="actions">
                <button mat-stroked-button (click)="onReset()">{{ 'card-section.reset' | translate }}</button>
                <button mat-flat-button color="primary" (click)="onSave()" [disabled]="form?.invalid || form?.pristine">
                    {{ 'card-section.save' | translate }}
                </button>
            </div>
        </div>

        <div class="card-section-content">
            <ng-content></ng-content>
        </div>
    </mat-card>
</article>

// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/screen_dimming.GET"
export interface IDaySchedule {
    enable_day: boolean;
    normal_hour: number;
    dimmed_hour: number;
}

export interface IDisplayForm {
    normal_brightness_level: number | undefined;
    dimming_mode: 'no_dim' | 'auto' | 'schedule' | undefined;
    dimmed_brightness_level: number | undefined;
    schedule: IDaySchedule[] | undefined;
}

export class DisplayFormModel implements IDisplayForm {
    normal_brightness_level: number | undefined;
    dimming_mode: 'no_dim' | 'auto' | 'schedule' | undefined;
    dimmed_brightness_level: number | undefined;
    schedule: IDaySchedule[] | undefined;

    constructor(obj?: IDisplayForm) {
        this.normal_brightness_level = obj?.normal_brightness_level;
        this.dimming_mode = obj?.dimming_mode;
        this.dimmed_brightness_level = obj?.dimmed_brightness_level;
        this.schedule = obj?.schedule;
    }
}

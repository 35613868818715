import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

import { LoginComponent } from './pages/login/login.component';
import { PasswordComponent } from './pages/password/password.component';
import { ResetPaswordComponent } from './pages/reset-pasword/reset-pasword.component';

import { NgParticlesModule } from 'ng-particles';

@NgModule({
    declarations: [LoginComponent, PasswordComponent, ResetPaswordComponent],
    imports: [CommonModule, SharedModule, AuthRoutingModule, NgParticlesModule],
})
export class AuthModule {}

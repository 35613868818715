import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard {
    constructor(private _router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // TODO: This is temporary until we have the simulator setup for each team member
        // when this is removed re-enable the test in the spec file
        return true;

        // TODO: Figure out something groovy for auth
        // If the authenticated path been executed
        if (sessionStorage.getItem('authToken')) {
            return true;
        }

        this._router.navigate(['/auth/login', { redirect: state.url }]);
        return false;
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-next-button',
    templateUrl: './next-button.component.html',
    styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent {
    @Input() isDisabled: boolean = false;

    constructor() {}

    handleClick() {
        if (!this.isDisabled) {
            // Should perform certain actions
        }
    }
}

// SCHEMA_VALIDATE: "$id": "response.config.v1./api/settings/config/time.GET"
export interface ITimeConfig {
    mode: string | undefined;
    timezone: string | undefined;
    update_interval: number | undefined;
    automatic_dst: boolean | undefined;
}

export class TimeConfigModel implements ITimeConfig {
    mode: string | undefined;
    timezone: string | undefined;
    update_interval: number | undefined;
    automatic_dst: boolean | undefined;

    constructor(obj?: ITimeConfig) {
        this.mode = obj?.mode;
        this.timezone = obj?.timezone;
        this.update_interval = obj?.update_interval;
        this.automatic_dst = obj?.automatic_dst;
    }
}

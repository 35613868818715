import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-card-section',
    templateUrl: './card-section.component.html',
    styleUrls: ['./card-section.component.scss'],
})
export class CardSectionComponent {
    @Input() title: string | undefined;
    @Input() enableActions: boolean | undefined;
    @Input() form: FormGroup | undefined;
    @Input() enableBackButton: boolean = false;
    @Input() requiresTrademark = false;

    protected backButtonText: string = ''; // Translation key

    @Output() saveEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output() resetEmitter: EventEmitter<void> = new EventEmitter<void>();

    constructor(protected _translationService: TranslateService) {
        this.backButtonText = 'general.back';
    }

    public onSave(): void {
        this.saveEmitter.emit();
    }

    public onReset(): void {
        this.resetEmitter.emit();
    }
}
